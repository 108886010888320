<template>
    <div class='page'>
        <div class="body1">
            <img class="product1" src="@/assets/images/partners1.png" alt="">
            <div class="info txt_center wow fadeInDown">
                <div class="title">{{ $t('partners.value1') }}</div>
                <div class="desc">{{ $t('partners.value2') }}</div>
            </div>
        </div>
        <div class="body2">
            <div class="title flex_btn">
                <img class=" wow fadeInDown" src="@/assets/images/index2.png" alt="">
                <div class=" wow fadeInDown">{{ $t('partners.value3') }}</div>
            </div>
            <img class="partners2" src="@/assets/images/partners2.png" alt="">
            <div class="partner flex_btn wow fadeInDown" data-wow-delay="0.3s">{{ $t('partners.value5') }}</div>
            <div class="partner flex_btn wow fadeInDown" data-wow-delay="0.6s">{{ $t('partners.value6') }}</div>
            <div class="partner flex_btn wow fadeInDown" data-wow-delay="0.9s">{{ $t('partners.value7') }}</div>
            <div class="partner flex_btn wow fadeInDown" data-wow-delay="1.2s">{{ $t('partners.value8') }}</div>
            <div class="partner flex_btn wow fadeInDown" data-wow-delay="1.5s">{{ $t('partners.value9') }}</div>
        </div>
        <div class="body3">
            <div class="title flex_btn wow fadeInDown">
                <img src="@/assets/images/index2.png" alt="">
                {{ $t('partners.value4') }}
            </div>
            <div class="partners dis_flex items_center wrap wow fadeInDown">
                <div class="item" :class="{'pointer': reg.test(item.link)}" v-for="(item, index) in partners" :key="index" @click="goLink(item.link)">
                    <img :src="$utils.getImgUrl(item.pic)" alt="">
                </div>
            </div>
        </div>
        <img class="partners3" src="@/assets/images/partners3.png" alt="">
    </div>
</template>

<script>
import { partnerList } from '@/api/index'
export default {
    data() {
        return {
            partners: [],
            reg: /(http|https):\/\/([\w.]+\/?)\S*/
        }
    },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live:false
            }).init()
        })
        this.partnerList()
    },
    methods: {
        goLink(link){
            if(this.reg.test(link)){
                window.open(link)
            }
        },
        partnerList(){
            partnerList({isShow: true}).then(res => {
                if(res.code == 0){
                    this.partners = res.data
                }
            })
        }
    },
}
</script>
<style scoped lang='scss'>
    .pointer{
        cursor: pointer;
    }
    .body1{
        position: relative;
        width: 100%;
        height: 800px;
        padding-top: 428px;
        box-sizing: border-box;
        .product1{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .info{
            color: #FFFFFF;
            position: relative;
            .title{
                font-size: 60px;
                font-weight: bold;
            }
            .desc{
                font-size: 22px;
            }
        }
    }
    .body2{
        width: 100%;
        height: 911px;
        position: relative;
        .title{
            color: #fff;
            font-size: 36px;
            font-weight: bold;
            position: absolute;
            top: 80px;
            left: 50%;
            transform: translateX(-50%);
            img{
                width: 72px;
                height: 72px;
                margin-right: 8px;
            }
        }
        .partners2{
            width: 100%;
            height: 100%;
        }
        .partner{
            position: absolute;
            height: 50px;
            width: fit-content;
            background: #FFFFFF;
            border-radius: 33px;
            border: 1px solid #0F2D76;
            padding: 0 35px;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
            transition: .3s;
            &:hover{
                background: #00239C;
                color: #fff;
            }
            &:nth-child(3){
                left: 26.3%;
                top: 316px;
            }
            &:nth-child(4){
                left: 36.6%;
                top: 481px;
            }
            &:nth-child(5){
                left: 50.63%;
                top: 348px;
            }
            &:nth-child(6){
                left: 61.09%;
                top: 426px;
            }
            &:nth-child(7){
                left: 66.46%;
                top: 251px;
            }
        }
    }
    .body3{
        padding: 50px 160px 0;
        box-sizing: border-box;
        .title{
            font-size: 36px;
            font-weight: bold;
            img{
                width: 72px;
                height: 72px;
                margin-right: 8px;
            }
        }
        .partners{
            margin-top: 80px;
            .item{
                width: 17%;
                height: 110px;
                padding: 20px;
                box-sizing: border-box;
                background: #FFFFFF;
                box-shadow: 0px 2px 9px 0px #D4DBF3;
                border-radius: 8px;
                margin-left: 3%;
                margin-bottom: 50px;
                &:nth-child(5n){
                    margin-right: 0;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .partners3{
        width: 100%;
    }
</style>